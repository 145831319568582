import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, PageProps } from 'gatsby';
import { Container, Grid } from '@mui/material';
import StandardFrame from '../../components/StandardFrame';
import PostHero from '../../components/Heros/PostHero';
import ArticleStyledHtml from '../../components/ArticleStyledHtml';
import StandardBreadcrumbs from '../../components/StandardBreadcrumbs';
import DemoFormSectionContent from '../../components/Sections/DemoFormSectionContent';
import PageSection from '../../components/Sections/PageSection';
import PageSectionTitle from '../../components/Sections/PageSectionTitle';
import PostCard from '../../components/Cards/PostCard';

export const pageQuery = graphql`
  query Post($id: String!) {
    current: markdownRemark(childPost: { id: { eq: $id } }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY", locale: "pl")
        card_image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 95, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
      html
    }
    featured: allMarkdownRemark(
      filter: { childPost: { id: { ne: null } }, frontmatter: { featured: { ne: null } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 2
    ) {
      edges {
        node {
          ...PostCardData
        }
      }
    }
    persona: allMarkdownRemark(
      filter: { childPersona: { id: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            demo_form
            demo_form_width
            demo_form_height
            order
          }
        }
      }
    }
  }
`;

export default ({ data: { current, featured, persona } }: PageProps<Queries.PostQuery>) => {
  return (
    <>
      <Helmet title={current!.frontmatter!.title!} />
      <StandardFrame>
        <PostHero
          maxWidth='lg'
          backgroundImage={current!.frontmatter!.card_image!.childImageSharp!.gatsbyImageData!}
          slug={current!.fields!.slug!}
          title={current!.frontmatter!.title!}
          date={current!.frontmatter!.date!}
        />
        <Container maxWidth='lg'>
          <StandardBreadcrumbs
            mt={3}
            path={[
              { title: 'Blog', link: '/blog' },
              { title: current!.frontmatter!.title!, link: current!.fields!.slug! }
            ]}
          />
        </Container>
        <Container maxWidth='md'>
          <ArticleStyledHtml my={{ xs: 3, md: 6 }} html={current!.html!} />
        </Container>
        <PageSection maxWidth='lg' bgcolor='tertiary.main'>
          <PageSectionTitle>Sprawdź jak Mediporta może wyglądać u Ciebie</PageSectionTitle>
          <DemoFormSectionContent
            forms={persona.edges.map(({ node }) => ({
              title: node.frontmatter!.title!,
              src: node.frontmatter?.demo_form,
              width: node.frontmatter!.demo_form_width!,
              height: node.frontmatter!.demo_form_height!
            }))}
          />
        </PageSection>
        <PageSection maxWidth='lg'>
          <PageSectionTitle>Polecane artykuły</PageSectionTitle>
          <Grid container spacing={3} mt={0} mb={3}>
            {featured.edges.map(({ node }) => (
              <Grid key={node.id} item xs={12} sm={6}>
                <PostCard
                  slug={node.fields!.slug!}
                  date={node.frontmatter!.date!}
                  title={node.frontmatter!.title!}
                  excerpt={node.excerpt!}
                  image={node.frontmatter!.card_image!.childImageSharp!.gatsbyImageData!}
                />
              </Grid>
            ))}
          </Grid>
        </PageSection>
      </StandardFrame>
    </>
  );
};
